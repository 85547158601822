@import 'swiper/css/bundle';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: 'Archia';
  src: url('../../public/fonts/archia-semibold.ttf');
  font-weight: 600;
  font-style: normal;
} */

/* Global variables. */
:root {
  --green-light: #dfe7e4;
  --brand: 0, 190, 142;
  --green-medium: #a3c2ba;
  --green-dark: #051415;
  --dark: #0d3335;
  --gray-dark: #75827d;
  --gray-light: 244, 247, 246;

  --off-white: #f9fafb;

  --black: 0, 0, 0;
  --white: #ffffff;
  --purple: #8164bf;
  --purple-light: #f2eff9;
  --turquoise: #189cc5;
  --turquoise-light: #e9f3f6;
  --blue: #4a69d4;
  --blue-light: #edf0fb;

  --text-primary: rgba(var(--gray-light), 1);
  --text-primary-medium: var(--green-medium);
  --text-primary-light: rgba(var(--gray-light), 0.5);

  --text-invert: rgba(var(--black), 1);
  --text-invert-medium: rgba(var(--black), 0.75);

  --text-accent: rgba(var(--brand), 1);

  --surface-primary: rgba(var(--black), 1);
  --surface-invert: var(--white);
  --surface-secondary: var(--off-white);
  --surface-brand: rgba(var(--brand), 1);

  --border-brand: rgba(var(--brand), 0.75);

  --swiper-scrollbar-bg-color: rgba(0, 190, 142, 0.15);
  --swiper-scrollbar-drag-bg-color: rgba(0, 190, 142, 1);
  --swiper-scrollbar-size: 10px;
}

@layer base {
  * {
    box-sizing: border-box;
  }

  html {
    @apply bg-surface-primary text-text-primary;
  }

  body {
    @apply body-1 w-full;

  }

  body.scroll-lock {
    @apply overflow-hidden;
  }

  #__next {
  }

  main {
  }

  h1 {
    @apply h1;
  }

  h2 {
    @apply h2;
  }

  h3 {
    @apply h3;
  }

  h4 {
    @apply h4;
  }

  h5 {
    @apply h5;
  }

  h6 {
    @apply h6;
  }

  /* button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    @apply btn-primary;
  } */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .markdown h1,
  .markdown h2,
  .markdown h3,
  .markdown h4,
  .markdown h5,
  .markdown h6,
  .html h1,
  .html h2,
  .html h3,
  .html h4,
  .html h5,
  .html h6 {
    @apply mt-8 mb-4;
  }

  .markdown h1,
  .html h1 {
    @apply text-5xl;
  }

  .markdown h2,
  .html h2 {
    @apply text-3xl;
  }

  .markdown h3,
  .html h3 {
    @apply text-2xl;
  }

  .markdown h4,
  .html h4 {
    @apply text-xl;
  }

  .markdown p,
  .html p {
    @apply my-4;
  }

  .markdown ol,
  .html ol {
    @apply list-decimal ml-4 my-4;
  }

  .markdown ul,
  .html ul {
    @apply list-disc ml-4 my-4;
  }

  .markdown li p,
  .html li p {
    @apply my-0;
  }

  .markdown img,
  .html img {
    @apply rounded-[20px];
  }

  .markdown th,
  .markdown tr td:first-child {
    @apply hidden;
  }

  .markdown table,
  .html table {
    @apply table-auto border-collapse;
  }

  .markdown td,
  .html td {
    @apply p-2 border border-white-600;
  }

  .markdown td p,
  .html td p {
    @apply my-0;
  }

  .markdown a,
  .html a {
    @apply text-brand underline;
  }

  .promobar-markdown .markdown p {
    margin: 0px;
  }


  @keyframes scroll {
    0% {
      transform: translate3d(0px, 0px, 0px)
    }
    100% {
      transform: translate3d(-100%, 0px, 0px)
    }
  }
}

@layer components {
  .display-xl {
    @apply font-aeonik text-8xl font-normal leading-[110%] -tracking-[0.035em];
  }

  .display {
    @apply font-aeonik text-7xl font-normal leading-[110%] -tracking-[0.035em];
  }

  .h1 {
    @apply font-aeonik text-5xl md:text-[68px] font-normal leading-[110%] -tracking-[0.035em];
  }

  .h2 {
    @apply font-aeonik text-[40px] font-normal leading-[120%] -tracking-[0.035em] md:text-[52px];
  }

  .h3 {
    @apply font-inter text-5xl font-semibold leading-[120%] -tracking-[0.02em];
  }

  .h4 {
    @apply font-inter text-4xl font-semibold leading-[120%] -tracking-[0.02em] md:text-[40px];
  }

  .h5 {
    @apply font-inter text-3xl font-normal leading-[140%];
  }

  .h6 {
    @apply font-inter text-2xl font-normal leading-[140%];
  }

  .h6-em {
    @apply font-inter text-xl font-bold leading-6 md:text-2xl md:leading-[140%];
  }

  .sub-heading {
    @apply font-inter text-base font-light leading-[150%] md:text-xl;
  }

  .body-1 {
    @apply font-inter text-lg font-normal leading-[150%];
  }

  .body-1-em {
    @apply font-inter text-lg font-bold leading-[150%];
  }

  .body-2 {
    @apply font-inter font-normal leading-[150%];
  }

  .body-2-em {
    @apply font-inter font-semibold text-base leading-[150%]
  }

  .caption-1 {
    @apply font-inter text-2xs font-normal leading-[150%] md:text-sm;
  }

  .caption-2 {
    @apply font-inter text-xs font-normal leading-[150%];
  }

  .special-1 {
    @apply font-inter text-sm font-medium leading-[140%] underline;
  }

  .special-2 {
    @apply font-inter text-xs font-medium leading-[140%] underline;
  }

  .btn {
    @apply flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-base font-medium leading-6 cursor-pointer;
  }

  .btn-2 {
    @apply flex items-center justify-center rounded-xl px-4 py-1 text-lg lg:text-base font-aeonik font-normal leading-6 cursor-pointer antialiased;
  }

  .btn-primary {
    @apply btn border border-brand bg-brand text-black hover:bg-primary-green-700 hover:border-primary-green-700 disabled:opacity-[0.35] disabled:cursor-not-allowed transition-all;
  }

  .btn-primary-inverse {
    @apply btn border border-black bg-black text-brand hover:opacity-80 disabled:opacity-[0.35] disabled:cursor-not-allowed transition-opacity;
  }

  .btn-naked-inverse {
    @apply btn text-black;
  }

  .btn-outline {
    @apply btn border border-primary-green-800 bg-transparent text-brand hover:bg-primary-green-900 disabled:opacity-[0.35] disabled:hover:bg-transparent disabled:cursor-not-allowed transition-all;
  }

  .btn-outline-light {
    @apply btn border border-primary-green-200 bg-transparent text-brand hover:bg-primary-green-100 disabled:opacity-[0.35] disabled:hover:bg-transparent disabled:cursor-not-allowed transition-all;
  }

  .btn-dark {
    @apply btn-2 h-[50px] lg:h-auto lg:leading-8 border-grey-800 bg-grey-800 text-primary-light disabled:opacity-[0.35] disabled:cursor-not-allowed transition-colors duration-300 hover:bg-grey-700;
  }

  .btn-primary-sm {
    @apply btn-primary caption-2 py-1;
  }

  .btn-outline-sm {
    @apply btn-outline caption-2 py-1;
  }

  .text-link {
    @apply text-base font-semibold text-brand hover:opacity-80 disabled:opacity-[0.35] transition-opacity;
  }

  .badge {
    @apply rounded-full px-2 font-inter text-xs font-medium leading-6 md:text-sm md:leading-7;
  }

  .badge-primary {
    @apply badge bg-primary-green-900 border-primary-green-700 text-brand;
  }

  .badge-wrapper {
    @apply flex items-center badge-gradient gap-1.5 rounded-[10px] px-2.5 py-1;
  }

  .badge-icon {
    @apply h-[18px] w-[18px] text-brand;
  }

  .badge-label {
    @apply pt-0.5 font-aeonik text-base font-medium leading-[18.75px];
  }

  .badge-blog-green {
    @apply border-[#007255] bg-[#00261C] text-[#00BE8E];
  }

  .badge-blog-purple {
    @apply border-[#4D3680] bg-[#1A122B] text-[#805AD5];
  }

  .badge-blog-cyan {
    @apply border-[#006D82] bg-[#00242B] text-[#00B5D8];
  }

  .badge-blog-pink {
    @apply border-[#802654] bg-[#2B0D1C] text-[#D53F8C];
  }

  .input-group {
    @apply
      flex
      flex-col
      items-center
      mx-auto
      gap-y-2
      w-full
      max-w-[400px]
      relative
      md:flex-row
      md:gap-y-0
  }

  .input-group .input-email {
    @apply
      border
    border-[rgba(255,255,255,.10)]
      py-[13px]
      rounded-lg
      black-02-gradient
      hover:black-05-gradient
      px-4
      text-[rgba(255,255,255,0.8)]
      placeholder:text-[rgba(255,255,255,0.8)]
      focus:outline-none
      max-w-none
  }

  .input-group button {
    @apply
      w-full
      h-[52px]
      md:h-auto
      md:w-auto
      md:absolute
      md:right-1.5
  }

  .input-email {
    @apply md:focus:outline w-full rounded-lg border border-[#00BE8E] md:border-[#004C39] bg-white px-3.5 py-3 text-base outline-[3px] outline-offset-2 placeholder:text-base placeholder:text-[#52615D] focus:border-[#00BE8E] focus:outline-[#00BE8E33] max-md:text-black  md:bg-transparent;
  }

  .input-email-white {
    @apply w-full rounded-lg border border-[#00BE8E] bg-white px-3.5 py-3 text-base outline-[3px] outline-offset-2 placeholder:text-base placeholder:text-[#52615D] focus:border-[#00BE8E] focus:outline-[#00BE8E33] text-black shadow-sm;
  }

  .feature-tooltip {
    @apply
      absolute
      bottom-[25px]
      -right-[115px]
      w-[230px]
      rounded-lg
    bg-[#1E1F21]
      py-2.5
      px-3
      opacity-0
      pointer-events-none
      duration-300
      transition-opacity
  }


  .feature-tooltip-active {
    @apply
      feature-tooltip
      opacity-100
      pointer-events-auto
  }

  .icon-feature-tooltip:before {
    @apply
      content-[""]
      absolute
      w-0
      h-0
      border-[8px]
      border-transparent
      border-t-[#1E1F21]
      bottom-[9px]
      -right-[1px]
      opacity-0
      pointer-events-none
      duration-300
      transition-opacity
  }

  .icon-feature-tooltip-active:before {
    @apply
      content-[""]
      absolute
      w-0
      h-0
      border-[8px]
      border-transparent
      border-t-[#1E1F21]
      bottom-[9px]
      -right-[1px]
      opacity-100
      pointer-events-auto
      duration-300
      transition-opacity
  }

  .icon-feature-tooltip:after {
    @apply
      content-[""]
      absolute
      w-[100px]
      h-4
      bg-brand
      bottom-[12px]
      left-[calc(50%-50px)]
      right-0
      opacity-0
      pointer-events-none
      duration-300
      transition-opacity
  }

  .icon-feature-tooltip-active:after {
    @apply
      content-[""]
      absolute
      w-[100px]
      h-4
      bg-brand
      bottom-[12px]
      left-[calc(50%-50px)]
      right-0
      opacity-0
      pointer-events-auto
      duration-300
      transition-opacity
  }
}

@layer utilities {

  /* horizontal padding */
  .px-contained {
    @apply
      px-4
      lg:px-8
      2xl:px-20;
  }

  .media-shadow {
    box-shadow: 0px 51.92px 14.497px 0px rgba(0, 0, 0, 0.00), 0px 33.377px 13.149px 0px rgba(0, 0, 0, 0.01), 0px 18.543px 11.126px 0px rgba(0, 0, 0, 0.04), 0px 8.429px 8.429px 0px rgba(0, 0, 0, 0.07), 0px 2.023px 4.72px 0px rgba(0, 0, 0, 0.08);

    @media (min-width: 1024px) {
      box-shadow: 0px 171.683px 47.938px 0px rgba(0, 0, 0, 0.00), 0px 110.368px 43.478px 0px rgba(0, 0, 0, 0.01), 0px 61.315px 36.789px 0px rgba(0, 0, 0, 0.04), 0px 27.871px 27.871px 0px rgba(0, 0, 0, 0.07), 0px 6.689px 15.608px 0px rgba(0, 0, 0, 0.08);
    }
  }

  .pill {
    background-color: rgba(252, 252, 252, 0.50);
  }

  .value-props-gradient {
    display: none;
    background: linear-gradient(83deg, rgba(2, 2, 7, 0.25) 0.35%, rgba(85, 5, 255, 0.25) 16.37%, rgba(0, 190, 142, 0.35) 100%);
    filter: blur(150px);
    border-radius: 0px 366px 366px 0px;

    @media (min-width: 1024px) {
      display: block;
    }

    @apply
      absolute
      top-0
      left-0
      w-full
      h-[120px]
  }


  .feature-steps-main-gradient {
    background: linear-gradient(136deg, rgba(155, 230, 208, 0.35) 15.74%, rgba(153, 224, 234, 0.35) 86.29%);
    filter: blur(150px);
    border-radius: 0px 366px 366px 0px;
    @apply
      absolute
      top-0
      right-0
      w-1/2
      h-[745px]
      max-h-full
      md:h-[745px]
  }

  .testimonial-block-gradient {
    border-radius: 0px 366px 366px 0px;
    background: linear-gradient(83deg, rgba(2, 2, 7, 0.30) 0%, rgba(85, 5, 255, 0.30) 16.37%, rgba(0, 190, 142, 0.30) 100%);
    filter: blur(150px);
    @apply
      absolute
      top-0
      left-0
      right-0
      h-[375px]
      w-full
      md:h-[206px]
  }

  .black-05-gradient {
    background: radial-gradient(
      100% 100% at 50% 0%,
      rgba(255, 255, 255, 0.00) 0%,
      rgba(255, 255, 255, 0.05) 100%),
      rgba(255, 255, 255, 0.05);
  }

  .black-02-gradient {
    background: radial-gradient(
      100% 100% at 50% 0%,
      rgba(255, 255, 255, 0.00) 0%,
      rgba(255, 255, 255, 0.05) 100%),
      rgba(255, 255, 255, 0.02);
  }

  @property --x {
    syntax: '<number>';
    inherits: false;
    initial-value: .0;
  }

  @property --y {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 1%;
  }

  @property --z {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 153%;
  }

  @keyframes northerLightsColorChange {
    0% {
      --y:0%;
      --z:153%;
      background-position: 50% 50%;
    }
    100% {
      --y:2.5%;
      --z:360%;
      background-position: 75% 100%;
    }
  }

  @keyframes colorChange {
    0% {
      --x:.0;
    }
    100% {
      --x:1;
    }
  }

  /* 153.25% 99.97% at 49.96% 0%, */
  .northern-lights-gradient {
    background: radial-gradient(
      var(--z) 99.97% at 49.96% var(--y),
      rgba(0, 0, 0, 1) 44.94%,
      rgba(0, 1, 75, 1) 60.34%,
      #00BE8E 75.75%,
      rgba(255, 255, 255, 0.00) 100%),
      linear-gradient(191deg, #000 24.81%,
      rgba(0, 0, 0, 0.00) 94.77%),
      url('https://cdn.shopify.com/s/files/1/0830/5750/8663/files/northern-lights-gradient-2x.png?v=1718395234') lightgray -872.462px 0px / 184.084% 100% no-repeat;
      animation: northerLightsColorChange 5s alternate infinite;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  
  .northern-lights-gradient-v2 {
    margin-top: -1px; /* Fix the gap */
    background: radial-gradient(
      2.5% 99.97% at 49.96% 360%,
      rgba(0, 0, 0, 0.45) 44.94%,
      rgba(0, 1, 75, 0.45) 60.34%,
      rgba(0, 190, 142, 0.50) 75.75%,
      rgba(255, 255, 255, 0.00) 100%),
      linear-gradient(191deg, #000 24.81%,
      rgba(0, 0, 0, 0.00) 94.77%),
      url('https://cdn.shopify.com/s/files/1/0830/5750/8663/files/northern-lights-gradient-2x.png?v=1718395234') lightgray -872.462px 0px / 184.084% 100% no-repeat;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      background-position: 75% 100%;
  }

  .black-to-white-gradient {
    background: radial-gradient(
        159.97% 99.97% at 49.96% 0%,
        rgba(0, 0, 0, 0.00) 37.41%,
        rgba(16, 168, 117, var(--x)) 80.66%,
        rgba(255, 255, 255, var(--x)) 100%,
        rgba(255, 255, 255, var(--x)) 100%),
        #000;
      animation: colorChange 1s;
      animation-fill-mode: forwards;
  }

  .brand-light-gradient {
    background: radial-gradient(
      147.62% 180.53% at 49.96% -42.8%,
      rgba(255, 255, 255, 0.00) 37.41%,
      rgba(16, 168, 117, 0.25) 100%),
      rgba(217, 245, 238, 0.50
    );
  }

  .black-to-green-gradient {
    background: linear-gradient(
      180deg,
      #000 0%,
      rgba(0, 0, 0, 0.00) 89.43%),
      radial-gradient(
        730.22% 219.37% at 49.96% 0%,
        rgba(0, 0, 0, 0.00) 0%,
        #00BE8E 88.25%
      ),
      #000;
  }

  .black-to-green-gradient-new {
    background: radial-gradient(
      153.25% 99.97% at 49.96% 0%,
      rgba(0, 0, 0, 0.90) 44.94%,
      #00BE8E 75.75%,
      rgba(255, 255, 255, 0.00) 100%),
      linear-gradient(180deg, #000 11.31%, rgba(0, 0, 0, 0.00) 36.7%),
      /* url(<path-to-image>) */
      lightgray -872.462px 0px / 184.084% 100% no-repeat;
  }


  .black-green-white-gradient {
    background: radial-gradient(
      192.78% 120.47% at 49.96% 0%,
      rgba(0, 0, 0, 0.00) 17.43%,
      rgba(16, 168, 117, var(--y)) 74.45%,
      rgba(255, 255, 255, var(--y)) 100%,
      rgba(232, 232, 232, var(--y)) 100%
    ),
    #000601;
  }

  .testimonial-black-green-white-gradient {
    background: radial-gradient(175.93% 109.94% at 49.96% 0%, rgba(0, 0, 0, 0.00) 37.41%, #00BE8E 80.5%, #FFF 100%, #FFF 100%), #000;
  }

  .badge-gradient {
    background:
    radial-gradient(
      478.05% 298.75% at 49.96% -61.25%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 190, 142, 0.5) 100%),
      linear-gradient(155.22deg, rgba(255, 255, 255, 0.05) 7.42%, rgba(255, 255, 255, 0) 61.47%);
  }

  .hero-carousel-gradient {
    background:
    radial-gradient(
      247.84% 111.17% at 50% -11.09%,
      #000 29.58%,
      rgba(0, 1, 75, 0.90) 59.6%,
      #2970FF 100%),
      #010101;
  }

  .storefronts-gradient {
    background: radial-gradient(
      241.74% 103.98% at 49.96% 0%,
      rgba(0, 0, 0, 0.50) 15.18%,
      rgba(0, 1, 75, 0.90) 50.3%,
      #00BE8E 100%
    );
  }

  .shops-gradient {
    background:
    radial-gradient(
      224.89% 99.97% at 49.96% 0%,
      rgba(0, 0, 0, 0.50) 5.18%,
      rgba(0, 1, 75, 0.90) 59.6%,
      #2970FF 100%);
  }

  .video-container {
    @apply md:bg-[rgba(204,_242,_232,_0.8)];
  }

  .video-chapter-slider-fade {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
  }

  .video-chapter-mark {
    position: relative;
  }

  .video-chapter-mark:before {
    content: '';
    width: 11px;
    height: 1px;
    background-color: #3D3D3D;
    position: absolute;
    top: calc(50% - 0.5px);
    left: 0
  }

  .last-video-chapter-mark:after {
    content: '';
    height: 16px;
    width: 2px;
    background-color: #000;
    position: absolute;
    left: -1px;
    top: 13px;
  }

  /* hide scrollbar */
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  .AccordionTrigger {
    @apply flex w-full items-center justify-between;
  }

  .AccordionTrigger[data-state='open'] > .AccordionChevron {
    @apply h-8 w-8 rotate-180 transition-transform;
  }

  .progress-bar {
    @apply flex h-3 w-full rounded-full bg-primary-green-900;
  }

  .progress-bar-inner {
    @apply h-3 rounded-full bg-[#989898];
  }

  .progress-bar-light {
    @apply flex w-full h-3 rounded-full bg-[#313131];
  }

  .progress-bar-slim {
    @apply h-1.5;
  }

  @keyframes progress-bar {
    from { width: 0% }
    to { width: 100% }
  }

  .progress-bar-animation {
    animation-name: progress-bar;
    animation-timing-function: linear;
  }

  .bg-tier-green-gradient,
  .bg-tier-transparent-gradient {
    transform: translateZ(0);
    padding-bottom: 4px;
  }

  .bg-tier-green-gradient:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: 0px -4px;
    background: linear-gradient(180deg, #00BE8E 0%, #10755B 100%);
    @apply
      rounded-t-2xl
      rounded-b-2xl
  }

  .bg-tier-transparent-gradient:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: 0px -4px;
    background: transparent;
    @apply
      rounded-t-2xl
      rounded-b-2xl
  }

  .active-toggle-button {
    @apply
    absolute
    left-0
    h-[calc(100%-8px)]
    z-[1]
    w-1/2
    rounded-full
    transition-all
  }

  .you-cursor {
    cursor: url('../../public/images/you-cursor.png'), auto;
  }

  .feature-step-nav-item {
    background: linear-gradient(136deg, #9BE6D0 15.74%, #99E0EA 86.29%), #00BE8E;
    @apply
      flex
      items-center
      justify-center
      rounded-full
      h-10
      w-10
      transition-all
      font-aeonik
      text-[22px]
      font-medium
  }

  .text-gradient {
    background: linear-gradient(134.28deg, #00BE8E 6.68%, #00BE8E 30.63%, #375DFB 91.91%);
    @apply bg-clip-text text-transparent;
  }

  .value-prop-icon {
    color: var(--value-prop-icon-color);
  }

  .value-prop:hover .value-prop-icon {
    color: var(--value-prop-icon-color-hover);
  }
}

/* Hubspot */
.form-columns-1, .form-columns-2 {
  @apply grid mb-3;
}

.form-columns-2 {
  @apply grid grid-cols-1 md:grid-cols-2 gap-8;
}

.hs-form-field label:not(.hs-error-msg) {
  @apply block mb-1.5 font-inter font-medium text-sm leading-5;
}

.hs-form-field .input .hs-input {
  @apply w-full rounded-lg border border-[#00261C] bg-transparent px-3 py-2 font-inter text-base leading-6 outline-none;
}

.hs-form-field .input .hs-fieldtype-textarea {
  @apply h-32;
}

.hs-form-field .inputs-list.multi-container {
  @apply columns-2;
}

.hs-form-field label.hs-form-radio-display span {
  @apply flex justify-start items-center gap-3;
}

.hs-form-field label.hs-form-radio-display input + span::before {
  content: '';
  @apply flex h-5 w-5 border border-[#00261C] rounded-md bg-transparent text-white;
}

.hs-form-field label.hs-form-radio-display input:checked + span::before {
  @apply bg-border-brand;
}

.hs-form-field .hs-form-radio-display .hs-input {
  @apply sr-only;
}

.hs-error-msg {
  @apply font-inter text-xs font-normal text-red-500 leading-4;
}

.hs-submit {
  @apply mt-8;
}

.hs-submit .actions .hs-button.primary {
  @apply btn btn-primary w-full;
}